.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: white;
}

.header .left {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-left: 15px;
}

.header .center {
  display: flex;
  justify-content: center;
  flex: 1;
}

.header .right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 25px;
}

.header .logoWrapper {
  display: flex;
  flex-direction: row;
}

.logo {
  width: 25px;
  height: 25px;
}

.logoText {
  line-height: 25px;
  margin-left: 10px;
}
